@import '../../_styles/colors';

.cash-sheets-search {
  margin: 5rem 5rem;

  &__title,
  &__back {
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 1.5rem;
  }

  &__back {
    display: flex;
    align-items: center;
    width: max-content;
  }

  .search-field-wrapper {
    width: 30rem;
  }

  h4 {
    font-weight: bold;
    margin: 2rem 0;
  }
}

@import '../../_styles/colors';

.header {
  .title-wrapper {
    display: flex;
    align-items: center;

    .changes-saved-wrapper {
      display: none;

      &.active {
        font-weight: bold;
        display: flex;
        color: $blue-light;

        p {
          font-size: 1.3rem;
          margin-left: 0.9rem;
        }
      }
    }
  }
}

.stockoverview-filter-wrapper {
  display: flex;
  max-height: 4rem;

  .search-field-wrapper,
  .input-date-wrapper {
    margin-right: 1.5rem;
  }

  button {
    &.primary {
      max-width: 7.5rem;
      margin-right: 1.5rem;
    }

    &.negative {
      max-width: 13.4rem;
      font-size: 1.3rem;
    }
  }
}

@import '../../_styles/constants';

.users {
  table {
    thead {
      th[name='firstName'] {
        width: $users-table-first-name-width;
      }
      th[name='lastName'] {
        width: $users-table-last-name-width;
      }
      th[name='mail'] {
        max-width: $users-table-email-width;
      }
      th[name='state'] {
        width: $users-table-state;
      }
      .not-sortable {
        width: $users-table-edit;
      }
    }
    tbody {
      .edit {
        text-align: right;
      }
    }
  }
}

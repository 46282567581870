.reports-page {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  .ui.menu {
    align-self: center;
    .item {
      width: 20rem;
    }
  }
}
$primary-color: #4a4a4a;
$primary-color-rgba: rgba(29, 29, 29, 0.5);
$primary-nav-color: #e5e5ea;
$table-head-color: rgba(38, 42, 51, 0.6);
$table-row-color: #f5f5f5;
$text-color: #000000;
$disabled-color: #eeeeee;
$placeholder-color: #b8bcbf;
$disabled-background-color: #eeeeee;
$error-background-color: #fff6f6;
$error-border-color: #e0b4b4;
$error-placeholder-color: #e7bdbc;
$input-border-color: rgba(34, 36, 38, 0.25);
$error-border-color: #e0b4b4;
$error-text-color: #9f3a38;

$shadow: rgba(34, 36, 38, 0.15);
$white-transparent: rgba(255, 255, 255, 0.2);

$blue-light: #189bc5;
$blue-bright-light: #d5e8f4;
$blue-lighter: #eff5f9;
$grey: #999fa3;
$grey-dark: #56585c;
$grey-light: #cacaca;
$grey-background: #aeaeb2;
$grey-border: #c7c7cc;
$red: #e6392d;
$red-light: #fceaea;
$green: #24c289;
$green-faded: #ebf2e6;
$orange: #ed8f21;
$orange: orange;
$purple: #6632fe;
$white: #ffffff;

@function darkenOnHover($color) {
  @return darken($color, 15%);
}

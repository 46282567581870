@import '../../_styles/constants';
@import '../../_styles/colors';

.bold,
.titel {
  font-weight: bold;
  &.userFormHeader {
    padding-top: 1.5rem;
  }
}
.edit-user {
  margin-top: 0rem;
}
.userForm {
  margin-top: 6.4rem;
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 6.4rem;
    box-sizing: border-box;
  }
  .input-wrapper {
    margin-bottom: 1.2rem;
  }
  .delete-button {
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 1.2rem;
    padding: 1.2rem 0;
    .icon.negative {
      color: $red;
      margin-right: 1.2rem;
    }
  }
  .ui.input,
  .marked,
  div[name='defaultStoreId'] {
    min-width: 0;
    width: 100%;
  }

  .marked {
    padding: 1.6rem 2.4rem;
    box-sizing: border-box;
    .titel {
      margin-bottom: 1.2rem;
    }
    &.store-access {
      margin-top: 4rem;
    }
    &.admin-rights {
      margin-top: 4rem;

      p {
        color: $primary-color;
        margin-bottom: 1.2rem;
      }
    }
    .items {
      div[role='listbox'] {
        width: 100%;
      }

      div[role='group'] {
        div[role='listbox'] {
          width: 18rem;
        }
        .input-wrapper {
          text-align: right;
          max-width: 20.6rem;
        }
        label {
          max-width: 20.6rem;
          padding-top: 0.9rem;
        }
      }
    }
    background-color: $table-row-color;
  }

  .ui {
    &.selection {
      &.dropdown {
        min-width: 0;
        div[role='option'] {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          svg {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.2rem 4.4rem 3rem;
    background-color: $white;
    z-index: 1;

    border-top: solid 1px;
    border-color: $grey-border;
    display: flex;
    justify-content: flex-end;
    a {
      display: inline-flex;
      justify-content: center;
    }
    .ui.primary.button {
      width: 10rem;
    }
    .action-buttons {
      display: flex;
    }
    .ui.negative.button {
      color: $red;
      border: 1px solid $red;
    }
  }
}

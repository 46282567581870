@import '../../_styles/colors';
@import '../../_styles/constants';

.input-wrapper {
  &.required label:after {
    content: '*';
    font-size: 2.5rem;
    color: $red;
    margin-left: 0.3rem;
    margin-top: 0.5rem;
  }

  label:not(:last-child) {
    display: flex;
    align-items: center;
    height: $input-icon-height;
    margin-bottom: $input-label-bottom-margin;
    font-size: 1.2rem;
    color: $grey-dark;

    > :not(:first-child) {
      margin-left: 0.75rem;
    }

    i,
    svg {
      width: $input-icon-height;
      height: $input-icon-height;
    }
  }

  .dropdown {
    font-size: 1.2rem;
  }
}

.ui.input,
.ui.selection.dropdown {
  min-width: 100%;
}

.bottom-bar {
  &__bank-transfer-table-wrapper {
    margin: 0 4rem;
    width: 65rem;
    table {
      font-weight: bold;
      td {
        padding: 0 1.2rem;
      }
    }
  }
}

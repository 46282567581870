@import '../../_styles/colors';

.ledger-decoration {
  &__header {
    display: flex;
    &__controls {
      display: flex;

      .input-date-wrapper {
        margin-left: 5rem;
        height: 100%;
        .datepicker-icon {
          height: 3.8rem;
        }
        .ui.input {
          width: 15rem;
          height: 100%;
          max-height: none;
        }
        .input-wrapper {
          height: 100%;
        }
      }
      .button {
        padding: 0.6rem 2rem;
        margin-left: 5rem;
        i.icon {
          margin-right: 1rem;
        }
      }
    }
  }
  .custom-table {
    width: 120rem;
    margin-top: 5rem;
  }
}

@import '../../_styles/colors';

.ledger-other-furniture {
  &__header {
    display: flex;
    margin-bottom: 2rem;
    &__controls {
      display: flex;
      .ui.input {
        width: 15rem;
        height: 100%;
        max-height: none;
      }
      .search-field-wrapper {
        margin-left: 5rem;
        height: 100%;
        .search-field {
          height: 100%;
        }
      }

      .input-date-wrapper {
        margin-left: 5rem;
        height: 100%;
        .datepicker-icon {
          height: 3.8rem;
        }

        .input-wrapper {
          height: 100%;
        }
      }
      .button {
        padding: 0.6rem 2rem;
        margin-left: 5rem;
        i.icon {
          margin-right: 1rem;
        }
      }
    }
  }
  .react-datepicker {
    &__year-wrapper {
      color: $white;
      justify-content: center;
    }

    &__navigation--previous,
    &__navigation--next {
      top: 0;
      background-image: none;
    }
  }
}

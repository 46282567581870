@import './_styles/colors';
@import './_styles/constants';
@import './_styles/detail';
@import './_styles/form';
@import './_styles/overview';
@import './_styles/reset';
@import './_styles/table';

html,
body {
  margin: 0;
  font-size: 10px;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;

  &,
  #root {
    min-height: 100%;
  }
}

button {
  color: $text-color;
  font-family: $font-family;
}

h1 {
  font-size: 2.4rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

h4 {
  font-size: 1.4rem;
  font-weight: 500;
}

h5 {
  font-size: 1.2rem;
  font-weight: bold;
}

h6 {
  font-size: 1.4rem;
  font-weight: 500;
}

body {
  font-size: 1.2rem;
}

main.stores,
.codes,
.users,
.profile {
  margin-top: 8rem;
}

a {
  color: $primary-color;
  &:hover {
    color: darkenOnHover($primary-color);
  }
}

button.plain {
  border: 0;
  background: transparent;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  outline: 0;
}

.info-message {
  background-color: $primary-color;
  opacity: 0.4;
  color: $white;
  text-align: center;
  padding: 1rem 2rem;
  font-size: 1.2rem;
}

main.stock {
  margin-top: 2rem;
}

@import '../../_styles/constants';

.codes {
  .ui.primary.button.newCodeButton {
    width: $management-overview-create-role-button;
  }
  .spacer {
    width: $management-overview-create-store-button;
  }
}

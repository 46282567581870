@import '../../../../_styles/colors';
@import '../../../../_styles/constants';

.secondary-nav {
  height: $second-navbar-height;
  margin-top: $second-navbar-distance-from-top;
  background-color: $primary-nav-color;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: relative;
  .store-links {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .mobile-menu {
    display: none;
  }
  .btn {
    padding: 1.2rem 1.8rem;
    font-weight: normal;
    height: $input-height;

    &.selected {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  @media (max-width: 767px) {
    .mobile-menu {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      &.open {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
    .store-links {
      display: none;
      &.mobile-menu-show {
        display: flex;
        flex-direction: column;
        background-color: $primary-nav-color;
        top: 4.8rem;
        position: absolute;
        z-index: 1;
        width: 100%;
        > div {
          padding: 1rem;
          width: 100%;
          border-bottom: 1px solid $shadow;
          text-align: center;
        }
      }
    }
  }
}

.color-bar {
  height: $color-bar-height;
  background-color: $blue-light;
}

@import '../../_styles/colors';
@import '../../_styles/constants';

.ui {
  &.input {
    input[name='code'] {
      max-width: $input-code-width;
    }

    input[name='serialNumber'] {
      max-width: $input-double-width;
    }
  }
}
.input-wrapper {
  div[name='state'] {
    max-width: $input-double-width;
  }
}

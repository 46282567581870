@import '../../_styles/colors';

main.prices {
  .header {
    .info-bar {
      display: flex;
      align-items: center;
      margin-top: 2rem;

      h4 {
        margin-right: 2rem;
      }

      .changes-saved-wrapper {
        display: none;

        &.active {
          font-weight: bold;
          display: flex;
          color: $blue-light;

          p {
            font-size: 1.3rem;
            margin-left: 0.9rem;
          }
        }
      }
    }
  }
  
  .ui.table th.icon.print, .ui.table td.icon.print {
    width: 5rem;
  }

  .filter-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 2.4rem;
    align-items: center;
    
    .ui.menu {
      margin: 0;
      margin-right: 4rem;
    }

    .search-field-wrapper {
      margin-right: 4rem;
    }
    .button {
      margin-right: 4rem;
      &:focus {
        background-color: darkenOnHover($blue-light);
      }
      .button-inner{
        font-weight: 600;
      }
      .icon {
        margin-right: 1.6rem;
      }
    }
    .as-text {
      color: $primary-color;
      font-weight: 600;
    }
  }
}

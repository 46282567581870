@import '../../../../_styles/colors';
@import '../../../../_styles/constants';

.main-menu {
  height: $navbar-height;
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $primary-color;
  border-bottom: 1px solid $shadow;
  box-shadow: 0 1px 2px 0 $shadow;
  padding: 0 2rem;
  color: $white;

  a.logo {
    display: inline-flex;
    svg {
      height: 4rem;
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 0 2rem;
    position: relative;

    > div {
      display: flex;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      padding: 2.2rem 2.4rem;
      font-size: 1.4rem;
      font-style: normal;
      text-transform: capitalize;
      color: $white;

      &:hover {
        opacity: 0.7;
      }

      &.active {
        text-shadow: 0px 0px 1px;
        opacity: 1;
        text-decoration: underline;
      }

      > :not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  button {
    color: $white;
  }
}

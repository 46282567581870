@media print {
  .other-furniture-table {
    overflow-x: hidden;
    margin-left: -5rem !important;
    margin-right: -5rem !important;
    table {
      min-width: 0 !important;
      * {
        padding: 1px !important;
        margin: 0 !important;
        width: auto !important;
        font-size: 1rem !important;
      }
    }
  }
}
.other-furniture-table {
  overflow-x: scroll;
  table {
    min-width: 170rem;
  }
}

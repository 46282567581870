@import '../../../_styles/colors';
@import '../../../_styles/constants';

$border-width: 0.5px;
$border-width-active: 1.5px;
$row-height: 3.5rem;
$table-cell-padding: 0.7rem;

.custom-table {
  &.readonly {
    pointer-events: none;
  }
  th,
  td {
    border: $border-width solid $grey-light;
  }
  tr {
    height: $row-height;
  }
  margin-bottom: $row-height + 3rem;
  &.is-loading {
    margin-bottom: 3rem;
  }
  .border-left {
    border-left: solid 1px $grey-dark;
  }
  .border-right {
    border-right: solid 1px $grey-dark;
  }
  .underline {
    text-decoration: underline;
  }
}

.custom-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    background-color: $blue-lighter;
    th {
      padding: $table-cell-padding;
      text-align: left;

      &.sortable {
        cursor: pointer;
        &:hover {
          background-color: $grey-light;
        }
        &:after {
          padding-left: 0.5rem;
          color: $grey;
        }
        &.ascending {
          &:after {
            content: '\2191';
          }
        }
        &.descending {
          &:after {
            content: '\2193';
          }
        }
      }
    }
  }

  tbody {
    tr {
      &.greyed-out {
        background-color: $grey-light;
      }
      &.negative {
        background-color: $red-light;
      }
      &.positive {
        background-color: $green-faded;
      }
    }

    td {
      padding: 1rem 0 1rem 1rem;
      a {
        color: inherit;
      }
      &.positive {
        background-color: $green-faded;
        color: #388000;
      }
      &.negative {
        background-color: $red-light;
        color: $red;
      }
      &.dropdown {
        padding: 0;
        overflow: visible;
      }
      &.cell {
        overflow-y: visible;
        text-overflow: ellipsis;
      }
      &.cell-editable {
        position: relative;
        cursor: text;
        padding: 0;
        * {
          height: calc(#{$row-height} - 2 *#{$border-width-active});
        }
        .input {
          border: 0;
          padding-left: calc(#{$table-cell-padding} - (#{$border-width-active} - #{$border-width}) / 2);
        }
        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: auto;
          line-height: normal;
        }
        div.active:before {
          content: '';
          display: block;
          position: absolute;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          background-color: transparent;
          border: $border-width-active solid $blue-light;
        }
      }
    }

    tr.row-spinner {
      border-top: 0;
    }
  }
}

@import '../../_styles/colors';
@media print {
  .bottom-bar {
    position: relative !important;
  }
}
.bottom-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4rem;
  border-top: 1px solid $grey-light;
  background-color: $white;
  padding: 1rem;
  font-weight: bold;
  td {
    padding-left: 1rem;
  }
  .negative {
    color: $red;
  }
  .positive {
    color: $green;
  }
}

@import '../../_styles/colors';

.base-modal {
  .close-icon {
    right: 2rem;
    top: 2rem;
    position: absolute;
    color: $grey;
  }

  .actions {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    .ui.button.primary {
      width: auto;
    }
  }

  .content {
    margin: 0;
    > :not(:first-child) {
      margin-top: 1.5rem;
    }

    font-weight: bold;
    line-height: 2.4rem;
  }

  div.header,
  header {
    background-color: $blue-light;
    height: 5.6rem;
    font-weight: bold;
    color: $white;
    font-size: 1.8rem;
    padding: 2rem 0.5rem 0.5rem 2.4rem;
    margin: 0rem 0rem 2.3rem 0rem;
  }

  form {
    background-color: $white;
    .content {
      padding: 0 3.2rem;
    }
    .actions {
      padding: 2rem 3.2rem 1.2rem;
    }
  }

  &.product-modal,
  &.prices-modal {
    width: 87.2rem;

    header {
      margin: 0;
      padding: 2rem 2.4rem 0.5rem 2.4rem;
      display: flex;
      justify-content: space-between;

      i {
        cursor: pointer;
        svg path {
          fill: $white;
        }
      }
    }

    .content {
      padding-right: 0;
    }

    .actions {
      padding-right: 3.2rem;
    }
  }
}

@import '../../_styles/colors';

.medium-stock {
  color: $purple;
}

.low-stock {
  color: $red;
}

.sync-failed {
  background-color: $error-border-color !important;
}

.greyed-out {
  background-color: $grey-light;
}

.products {
  .ui.table {
    thead th {
      padding: 1rem 0.4rem;
    }
    tbody tr td {
      padding: 0 0.4rem;
    }
  }
  input.small {
    max-width: 5rem;
  }

  @media (max-width: 767px) {
    .ui.table:not(.unstackable) thead {
      display: table-header-group !important;
    }
    .ui.table:not(.unstackable) tbody {
      display: table-row-group !important;
    }
    .ui.table:not(.unstackable) tr {
      display: table-row !important;
    }
    .ui.table:not(.unstackable) tr > th,
    .ui.table:not(.unstackable) tr > td {
      display: table-cell !important;
    }
    .ui.table:not(.unstackable) tr {
      th[name='edit'],
      td[name='edit'],
      th[name='product.dimensions'],
      td[name='product.dimensions'],
      th[name='product.remark'],
      td[name='product.remark'],
      th[name='product.reserved'],
      td[name='product.reserved'],
      th[name='stock-item.zone'],
      td[name='stock-item.zone'],
      th[name='stock-item.expected'],
      td[name='stock-item.expected'],
      th[name='price.purchasePrice'],
      td[name='price.purchasePrice'],
      th[name='price.sellingPrice'],
      td[name='price.sellingPrice'],
      th[name='price.total'],
      td[name='price.total'] {
        display: none !important;
      }
    }
  }
}

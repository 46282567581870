@import '../../_styles/colors';

main {
  .changes-saved-wrapper {
    display: none;

    &.active {
      font-weight: bold;
      display: flex;
      color: $blue-light;

      p {
        font-size: 1.3rem;
        margin-left: 0.9rem;
      }
    }
  }

  .multiple-products-edit {
    .ui.checkbox label {
      max-width: 16.4rem;
      font-weight: bold;
      color: $blue-light !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        top: 1rem;
      }
    }
    .ui.toggle.checkbox input:checked ~ label {
      color: $blue-light !important; // Needs to be important to go over the checked state that is also important
    }
  }
  .filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    margin: 2rem 0 2.4rem;

    .queries {
      display: flex;
      align-items: center;

      .search-field {
        margin-right: 4rem;
      }

      .input-wrapper {
        .toggle {
          label {
            font-size: 1.4rem;
          }
        }
      }
    }
    .legend-wrapper {
      display: flex;
      &__items {
        margin-left: 1rem;
        :nth-child(1) {
          color: purple;
        }
        :nth-child(2) {
          color: red;
        }
        :nth-child(3) {
          color: $error-text-color;
        }
      }
    }

    .purchase-total {
      span {
        font-weight: bold;
      }
    }
  }

  @media (max-width: 767px) {
    .actions {
      display: none;
    }
    .filter-wrapper {
      flex-direction: column;
      .queries {
        flex-direction: column;
        .search-field-wrapper {
          margin-bottom: 1rem;
        }
        .ui.menu .item {
          width: 16rem;
        }
      }
    }
    .legend-totals {
      display: none;
    }
  }
}

input[name='color'] {
  height: 3rem;
}
.base-modal {
  form {
    .group {
      .ui.dropdown {
        font-size: 1.2rem;
      }
    }

    .actions {
      .ui.primary.button.newStoreButton {
        width: 10rem;
      }
      padding-right: 0px;
    }
  }
}

@import '../../../_styles/colors';

.group-header-table {
  margin-top: 5rem;
  td {
    font-weight: bold;
    padding: 1rem;
    &.active {
      text-align: center;
      border: 0.5px solid $grey-light;
      background-color: $blue-bright-light;
    }
  }
}

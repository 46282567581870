.prices-form {
  display: flex;
  justify-content: space-between;

  input {
    max-width: 20rem;
  }

  .print-format {
    max-width: 20rem;

    .ui.dropdown {
      max-width: 20rem;
      font-size: 1.2rem;
    }
  }
}

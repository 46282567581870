@import '../../../_styles/colors';

.prices-form {
  .content-wrapper {
    display: flex;
    justify-content: space-between;

    .input-wrapper {
      margin-bottom: 1rem;
    }

    .product-wrapper {
      width: 60%;
      padding-top: 2.4rem;
    }

    .price-wrapper {
      background-color: $grey-light;
      padding: 2.4rem 3.2rem 7rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .actions {
    .ui.button.primary {
      max-width: 10rem;
    }
  }
}

@import '../../../_styles/colors';

.product-form {
  .content-wrapper {
    display: flex;
    justify-content: space-between;

    .input-wrapper {
      margin-bottom: 1rem;
    }

    .product-wrapper {
      width: 60%;
      padding-top: 2.4rem;
    }

    .price-wrapper {
      background-color: $grey-light;
      padding: 2.4rem 3.2rem;
      display: flex;
      flex-direction: column;
      // Only if one or more price fields are shown, add margin to the last one
      // (If no price fields are shown, there will not be a child div)
      > div:first-of-type > div:last-of-type {
        margin-bottom: 5rem;
      }
    }
  }
  .actions {
    .ui.button.primary {
      max-width: 15rem;
    }
  }
}

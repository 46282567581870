@import '../../_styles/colors';

@media print {
  .print-page {
    page-break-before: always;
  }
  .main-menu {
    display: none !important;
  }
  button {
    display: none !important;
  }
}
.cash-sheets-overview {
  margin: 5rem 5rem;
  &__input {
    display: flex;
    margin-bottom: 5rem;
    .button {
      display: flex;
      margin-left: 4rem;
      padding: 0.6rem 2rem;
      font-size: 1.2rem;

      i.icon {
        height: 2rem;
        margin-right: 1rem;
      }
    }
    &__check {
      display: none;

      &.change-saved {
        display: flex;
        height: 3rem;
        color: $blue-light;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
      }

      i.icon {
        height: 2.4rem;
        margin-left: 4rem;
      }

      &__text {
        margin-left: 1.5rem;
        display: block;
      }
    }
  }
  &__title {
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 1.5rem;
  }

  .input-date-wrapper {
    width: 15rem;
    .ui.input {
      display: block;
    }
  }

  &__table-title {
    font-weight: bold;
    margin: 2rem 0;
  }
  table {
    width: 100%;
  }
}

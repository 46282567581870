@import '../../_styles/colors';

.toolbar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5rem;
  border-top: 1px solid $grey-light;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  &__tools {
    height: 3rem;
    display: flex;

    &__highlight {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      i.icon {
        margin: 0.5rem;
      }
      &__none {
        svg path {
          stroke: $text-color;
        }
        svg circle {
          stroke: $grey-light;
          fill: $white;
        }
      }

      &__negative {
        margin: 1rem;
        svg path {
          stroke: $white;
        }
        svg circle {
          stroke: none;
          fill: $red;
        }
      }

      &__positive {
        svg path {
          stroke: $white;
        }
        svg circle {
          stroke: none;
          fill: $green;
        }
      }
    }
    .ui.button.negative {
      border: none;
      border-right: 1px solid $grey-light;
      border-radius: 0;
      color: $primary-color;
      font-weight: 600;
      i.icon svg path {
        color: $blue-light;
        stroke: $blue-light;
      }
    }
  }
  &__cancel {
    right: 1rem;
    top: 1rem;
    .ui.button.negative {
      border: none;
      font-weight: 600;
    }
  }
}

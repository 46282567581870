@import '../../../_styles/colors';
@import '../../../_styles/constants';

.bold {
  font-weight: bold;
}
.custom-input {
  width: 100%;
  outline: none;
  text-align: left;
  padding: 1rem 0.7rem;
  background: transparent;
  border: 1px solid $input-border-color;
  color: $text-color;
  box-sizing: border-box;
  height: $input-height;
  font-family: $font-family;

  &::placeholder {
    color: $placeholder-color;
  }

  &:disabled {
    opacity: $disabled-opacity;
    background-color: $disabled-background-color;
  }

  &.error {
    color: $error-text-color;
    border-color: $error-border-color;
    background-color: $error-background-color;

    &::placeholder {
      color: $error-placeholder-color;
    }

    &:focus {
      border-color: $red;
    }
  }

  &:focus {
    border-color: $primary-color;
  }

  &::placeholder {
    color: $placeholder-color;
  }
}

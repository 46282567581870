@import './colors';

main {
  a ~ header {
    margin-top: 2rem;
  }
  header {
    h1 {
      margin-bottom: 0;
    }
  }

  section {
    margin-top: 2rem;

    h2 {
      margin-bottom: 1rem;
    }

    &:not(:last-child):after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $shadow;
      margin: 3rem 0;
    }
  }
}

@import '../../_styles/colors';
@import '../../_styles/constants';

.stores {
  table {
    thead {
      th[name='code'] {
        width: $stores-table-code-width;
      }
      th[name='name'] {
        width: $stores-table-name-width;
      }
      th[name='description'] {
        width: $stores-table-description-width;
      }
      th[name='contactPerson'] {
        width: $stores-table-contact-person-width;
      }
      th[name='contactEmail'] {
        width: $stores-table-contact-email-width;
      }
      th[name='serialNumber'] {
        width: $stores-table-serial-number-width;
      }
      th[name='active'] {
        width: $table-active-width;
      }
      th[name='edit'] {
        width: $table-edit-width;
      }
    }

    tbody {
      .serialNumber {
        text-align: center;
      }

      .edit {
        text-align: right;
      }

      .greyed-out {
        background-color: $grey-light;
      }
    }
  }
}

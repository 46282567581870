@import '../../_styles/colors';

.search-field-wrapper {
  position: relative;

  > button.plain {
    position: absolute;
    right: 0.7rem;
    top: 0.1rem;
    background: $white;
    .icon {
      color: $grey-dark;
    }
  }
}

@import '../../_styles/colors';

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  .loginbox {
    width: 38rem;
    .formbox {
      padding: 3.2rem;
      border: 1px solid;
      border-color: $grey-border;
       h1{
         font-size: 1.8rem;
         margin-bottom: 3.2rem;
       }

      button {
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 1.6rem;
      }

      .actions {
        display: block;
        text-align: center;

        a {
          padding-top: 1.6rem;
          color: #189bc5;
        }
      }
    }
  }
}

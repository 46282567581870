@import '../../_styles/constants';

.users {
  .header {
    margin-bottom: 1.6rem;
    .ui.menu {
      margin-bottom: 0;
    }
  }

  .email-column {
    width: 25rem;
  }

  .search-field-wrapper {
    width: $management-overview-search-width;
  }
}

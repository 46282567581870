@import '../../_styles/constants';

.header {
  margin-bottom: 1.6rem;
  .ui.menu {
    margin-bottom: 0;
  }

  .overview-title {
    width: $management-overview-title;
    font-weight: bold;
    line-height: 2.4rem;
  }
}

.ui.primary.button.overview {
  width: $management-overview-create-store-button;
}

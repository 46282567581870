@import '../../../_styles/colors';

@media print {
  .revenue-per-seller__table {
    max-height: none !important;
  }
}
.revenue-per-seller {
  width: 35rem;
  margin: 3rem 0;
  &__summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    h4 {
      font-weight: bold;
    }

    &__seller {
      display: flex;
      &__name {
        width: 3rem;
        margin-right: 1rem;
      }
      i.icon svg path {
        fill: none;
        stroke: $blue-light;
      }
    }

    &__total-price {
      display: flex;
      &__text {
        margin-right: 1rem;
      }
    }
  }
  &__table {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;

    &.is-expanded {
      transition: max-height 1s ease-out;
      height: auto;
      max-height: 8000px;
    }

    .custom-table {
      margin-bottom: 0;
    }
  }
}

$font-family: 'Open Sans', sans-serif;

$navbar-height: 6.5rem;
$second-navbar-distance-from-top: 6.5rem;
$second-navbar-height: 4rem;
$input-height: 2.9rem;
$color-bar-height: 0.8rem;
$disabled-opacity: 0.75;

$management-overview-title: 7rem;
$management-overview-create-store-button: 18.8rem;
$management-overview-create-role-button: 15.6rem;
$code-overview-create-code-button: 10rem;

$input-icon-height: 2.2rem;
$input-label-bottom-margin: 0.5rem;

$management-overview-search-width: 32rem;

$table-edit-width: 8rem;
$table-active-width: 8rem;

$stores-table-code-width: 7.2rem;
$stores-table-name-width: 15%;
$stores-table-description-width: 15%;
$stores-table-contact-person-width: 10%;
$stores-table-contact-email-width: 10%;
$stores-table-serial-number-width: 11.5rem;

$users-table-first-name-width: 14rem;
$users-table-last-name-width: 17rem;
$users-table-email-width: 51.7rem;
$users-table-state: 8rem;
$users-table-edit: 6rem;

$codes-table-serial-number-width: 13.6rem;
$codes-table-description-width: 26rem;
$codes-table-code-width: 13.6rem;

$input-code-width: 15.9rem;
$input-double-width: 12rem;
$input-user-width: 48rem;

.base-modal.user-delete-modal {
  border-radius: 0;
  max-width: 38.4rem;
  header {
    font-size: 1.8rem;
    font-weight: bold;
  }
  p {
    font-weight: normal;
    margin-top: 1rem;
  }
  .ui.button.primary {
    width: auto;
  }
}

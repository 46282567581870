@import '../../../../_styles/_colors.scss';

.bank-sheet {
  padding-left: 3rem;
  width: 15%;
  border-left: 1px solid $grey-light;
  display: flex;
  flex-direction: column;
  min-width: 20rem;

  &__bank {
    display: flex;
    flex-direction: column;
    height: 6rem;
    justify-content: space-evenly;

    &__added-bank {
      position: relative;
      button {
        color: $primary-color;
        top: 0.3rem;
        position: absolute;
        right: 2rem;
        width: 2.4rem;
      }
    }
  }

  &__add-bank {
    font-weight: bold;
    color: $blue-light;
    display: flex;
    button {
      width: 2.4rem;
    }
    i.icon svg path {
      fill: none;
      stroke: $blue-light;
    }

    p {
      padding-top: 1.2rem;
      padding-left: 0.5rem;
    }
  }
}

@import '../../../_styles/colors';
@import '../../../_styles/constants';

.input-date-wrapper {
  position: relative;
  .react-datepicker__day--selected {
    color: $blue-light;
    background-color: $white;
  }
  .datepicker-icon {
    position: absolute;
    right: 1rem;
    height: $input-height;
    top: 0;

    & > * {
      color: $primary-color;
    }
  }

  &.has-label {
    .datepicker-icon {
      top: calc(#{$input-icon-height} + #{$input-label-bottom-margin});
    }
  }
  .react-datepicker {
    &__year-wrapper {
      color: $white;
      justify-content: center;
    }

    &__navigation--previous,
    &__navigation--next {
      top: 0;
      background-image: none;
    }
  }
}

.input-text-wrapper {
  cursor: text;
  padding-top: 0;
  padding-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  input {
    border: none;
    width: 100%;
  }

  &__display-value {
    width: 100%;
    line-height: 1;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@import '../../_styles/colors';
@import '../../_styles/constants';

.ui.table {
  color: $text-color;
  th.icon, td.icon {
    width: 3.5rem;
    &.print {
      width: 4rem;
    }
  }

  td.table-empty {
    padding: 2rem;
    text-align: center;
    font-size: 1.4rem;
  }

  tr.greyed-out {
    background-color: $grey-light;
  }

  &.table {
    font-size: 1.2rem;
    line-height: 2rem;
    thead {
      th {
        font-weight: normal;
        background-color: $table-head-color;
        color: $white;
        padding: 1rem 0.8rem;

        &:hover {
          background-color: $table-head-color;
        }

        &.not-sortable {
          background-color: $table-head-color;
          &:hover {
            background-color: $table-head-color;
          }
        }

        &.sorted {
          &.ascending {
            background-color: $table-head-color;
          }

          &.descending {
            background-color: $table-head-color;
          }
        }
      }
    }

    tbody {
      tr {
        height: 2.5rem;

        td {
          vertical-align: middle;
          padding: 0 1.1rem;

          input {
            height: 2.5rem;
            width: 100%;
            padding-right: 0.5rem;
            border: 1px solid $grey-border;
            padding: 0 0.4rem;
            box-shadow: none;
            outline: none;
            &:focus {
              border-color: $blue-light;
            }
          }
        }
        tr:nth-child(even) {
          background-color: $table-row-color;
        }
        tr:nth-child(odd) {
          background-color: $white;
        }
      }
    }
  }
}

.ui.fixed.table td.icon {
  text-overflow: clip;
}
.bottom-bar {
  display: block;
  &__table-wrapper {
    overflow-x: scroll;
    margin: 0 4rem;
    table {
      min-width: 170rem;
      font-weight: bold;
    }
  }
}

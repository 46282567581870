@import '../../_styles/colors';

.request-password-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  .back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.6rem;
    svg {
      margin-right: 1.6rem;
    }
  }
  .reset-pw-box {
    width: 38rem;
    .formbox {
      padding: 3.2rem;
      border: 1px solid;
      border-color: $grey-border;
      h1{
        font-size: 1.8rem;
        margin-bottom: 3.2rem;
      }

      label{
        margin-top: 2rem;
      }

      button {
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 1.6rem;
      }
    }
  }
}

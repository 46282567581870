@import '../../_styles/colors';

.error-message {
  color: $red;
  display: flex;
  align-items: center;
  animation-name: fadeInDown;
  animation-duration: 0.1s;

  span {
    font-weight: 500;
    font-size: 1.2rem;
  }

  &.global {
    background-color: $red-light;
    padding: 0.8rem 1.6rem;
    display: flex;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 1.4rem;
      margin-bottom: 0;
      margin-left: 0.6rem;
    }
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

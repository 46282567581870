.reports-bestsellers {
  .actions {
    display: flex;
    justify-content: space-between;
  }
  .filters {
    display: flex;
    > div {
      margin-right: 1rem;
      label {
        font-weight: bold;
      }
    }
  }
}
table {
  width: 100%;
  table-layout: fixed;
  .smallest-column {
    width: 4rem;
  }
  .smaller-column {
    width: 5rem;
  }
  .date-column,
  .currency-column {
    width: 8.5rem;
  }
  .small-column {
    width: 7rem;
  }
  .medium-column {
    width: 10rem;
  }
  .large-column {
    width: 20rem;
  }
  th.centered-text,
  td.centered-text {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }
}

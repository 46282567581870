@import '../../_styles/colors';

.section-change-password {
  justify-content: space-between;
  font-family: Open Sans;
  font-style: normal;
}
.change-password-modal {
  justify-content: space-between;
  font-family: Open Sans;
  font-style: normal;
  .header {
    font-size: 2rem;
    font-weight: bold;
    margin: 2rem;
  }
  .actions {
    margin-right: 3.2rem;
  }
}

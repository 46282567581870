@import '../../_styles/colors';

.logobox {
 
  padding: 2rem;
  background-color: $grey-background;
  display: flex;
  justify-content: center;
  align-content: center;
  a{
      display: inline-flex;
  }
}

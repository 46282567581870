@import '../../_styles/constants';

.roleForm {
  .dropdownType {
    div[name='type'] {
      width: 24rem;

      div.active.selected.item {
        width: 100%;
      }
    }
  }

  .stateDropdown {
    div[name='state'] {
      min-width: 0px;
    }
  }

  .ui.primary.button {
    width: $code-overview-create-code-button;
  }
}

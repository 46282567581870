@import '../../_styles/colors';
@import '../../_styles/constants';

.codes {
  table {
    thead {
      th[name='code'] {
        width: $codes-table-code-width;
      }
      th[name='code'] {
        width: $codes-table-description-width;
      }
      th[name='serialNumber'] {
        width: $codes-table-serial-number-width;
      }
      th[name='active'] {
        width: $table-active-width;
      }
      th[name='edit'] {
        width: $table-edit-width;
      }
    }
    tbody {
      .serialNumber {
        text-align: right;
        padding-right: 3rem;
      }

      .edit {
        text-align: right;
      }

      .greyed-out {
        background-color: $grey-light;
      }
    }
  }
}

@import '../../_styles/colors';

.error-center {
  animation: fadeIn 1s;
  margin-top: 10rem;
  text-align: center;
  color: $grey;
  font-size: 1.4rem;
  font-style: italic;
}
@keyframes fadeIn {
  0% {opacity:0;}
  30% {opacity:0;}
  100% {opacity:1;}
}

.section-status {
  .status {
    font-weight: bold;
    &.ACTIVE {
      color: $green;
    }
    &.INACTIVE {
      color: $red;
    }
    &.REGISTERING {
      color: $orange;
    }
  }

  .description {
    color: $grey;
    font-weight: 1.4rem;
    margin-top: 0.5rem;
  }
}

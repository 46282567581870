@import '../../../../_styles/_colors.scss';

$row-height: 3.2rem;
$border: 1px solid $primary-color;

.overview-wrapper {
  background-color: $blue-lighter;
  display: flex;
  width: 90%;
  padding: 3rem;
  color: $primary-color;
  margin-bottom: 5rem;

  &.readonly {
    pointer-events: none;
  }

  .input-text-wrapper {
    overflow: auto;
    margin-right: 2rem;
    border: 0.5px solid $primary-color;
    height: $row-height;
    background-color: $white;

    &__display-value {
      padding-left: 0.7rem;
    }
  }

  &__cash-sheet {
    width: 85%;
    display: flex;
    flex-direction: column;
    min-width: 80rem;

    &__table {
      table-layout: fixed;
      width: 100%;

      tr {
        height: $row-height;
        .negative {
          color: $red;
        }
        .positive {
          color: $green;
        }
      }

      &__arrow-left,
      &__arrow-right {
        position: relative;
        width: 60%;
        height: 2rem;
        display: block;
        border-bottom: $border;
      }
      &__arrow-right {
        right: 50%;
        border-right: $border;
      }
      &__arrow-left {
        left: 20%;
        border-left: $border;
      }
    }
  }
}

@import '../../../_styles/colors';
@import '../../../_styles/constants';

.custom-dropdown {
  .dropdown__control {
    background-color: transparent;
    border: none;
    box-shadow: none;

    &:hover {
      border-color: $input-border-color;
    }

    &.dropdown__control--is-focused,
    &.dropdown__control--menu-is-open {
      border-color: $primary-color;
    }

    &.dropdown__control--menu-is-open {
      .dropdown__indicators {
        .dropdown__indicator {
          color: $primary-color;
        }
      }
    }

    .dropdown__value-container {
      cursor: text;
      .dropdown__placeholder {
        color: $placeholder-color;
      }
      .dropdown__single-value {
        font-weight: bold;
      }
      .dropdown__multi-value {
        background-color: $grey-light;

        .dropdown__multi-value__label {
          color: $text-color;
        }
        .dropdown__multi-value__remove {
          color: $grey;
          &:hover {
            color: $red;
            background-color: inherit;
            cursor: pointer;
          }
          svg {
            height: 1.8rem;
            width: 1.8rem;
          }
        }
      }
    }

    .dropdown__indicators {
      .dropdown__indicator-separator {
        display: none;
      }
      .dropdown__indicator {
        color: $primary-color;
      }
    }
  }

  .dropdown__menu {
    background-color: $white;
    z-index: 100;
    .dropdown__option {
      padding: 0.8rem 1.7rem;
      &.dropdown__option--is-focused,
      &.dropdown__option--is-selected {
        background-color: $grey-light;
        color: $text-color;
      }
    }
    .dropdown__menu-notice--no-options {
      color: $grey;
      text-align: left;
      padding: 0.8rem 1.7rem;
    }
  }

  &.dropdown--is-disabled {
    pointer-events: none;
    opacity: $disabled-opacity;
    background-color: $disabled-background-color;
  }

  &.contains-empty-option {
    .dropdown__option:first-child {
      color: $grey;
    }
  }

  &.error {
    .dropdown__control {
      color: $error-text-color;
      border-color: $error-border-color;
      background-color: $error-background-color;

      .dropdown__value-container {
        .dropdown__placeholder {
          color: $error-placeholder-color;
        }
      }

      &.dropdown__control--is-focused,
      &.dropdown__control--menu-is-open {
        border-color: $red;
      }

      &.dropdown__control--menu-is-open {
        .dropdown__indicators {
          .dropdown__indicator {
            color: $red;
          }
        }
      }
    }

    .dropdown__indicators {
      .dropdown__indicator {
        color: $error-placeholder-color;
      }
    }
  }
}

@import '../_styles/colors';

.ledger-page {
  display: flex;
  flex-direction: column;
  h3 {
    color: $primary-color;
    margin-bottom: 1rem;
  }
  &__content {
    margin: 2.5rem 5rem;
  }
}

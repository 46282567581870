@import '../../_styles/colors';
@import '../../_styles/constants';

$padding: 0.8rem 1.6rem;
$icon-height: 2rem;

.ui.button {
  padding: $padding;

  &.primary {
    background-color: $blue-light;

    span,
    .button-inner {
      font-weight: 600;
      font-size: 1.3rem;
      max-height: 3.5rem;
    }

    &:hover {
      background-color: $blue-light;
    }
  }

  &.negative {
    border: 1px solid $blue-light;
    background-color: white;
    color: $blue-light;
    font-size: 1.3rem;

    &:hover {
      background-color: transparent;
      color: $blue-light;
    }

    &:focus {
      background-color: transparent;
      color: $blue-light;
    }
  }
}

button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > :not(:first-child) {
    margin-left: 0.8rem;
  }

  i,
  svg {
    width: $icon-height;
    height: $icon-height;
  }
}

@mixin setColorTo($color) {
  color: $color;
  &:hover,
  &:focus {
    outline: transparent;
    color: darkenOnHover($color);
  }
}

button.as-text {
  background: transparent;
  border: none;
  font-size: 1.3rem;
  line-height: 1.8rem;
  max-height: 3.5rem;
  text-align: center;
  cursor: pointer;
  font-family: $font-family;
  white-space: nowrap;

  @include setColorTo($blue-light);
  &.primary {
    @include setColorTo($primary-color);
  }
  &.negative {
    @include setColorTo($red);
  }

  &.loading {
    color: transparent;
    &:hover,
    &:focus {
      color: transparent;
    }

    .loader-wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
    }
  }
  .icon {
    @include setColorTo($primary-color);
  }
}

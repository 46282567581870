@import '../../_styles/colors';

.ui {
  &.menu {
    border-color: $primary-color;
    max-height: 3.8rem;
    margin-top: 0;

    .item {
      width: 18.3rem;
      justify-content: center;
      font-size: 1.3rem;
      line-height: 1.8rem;
      font-weight: 600;
      padding: 0;
      height: 3.6rem;

      &.active {
        background-color: $primary-color;
        color: $white;
        font-weight: 600;

        &:hover {
          background-color: $primary-color;
          color: $white;
        }
      }
    }

    > a:last-of-type .item:before {
      width: 0;
    }

    .active .item {
      background-color: $primary-color;
      color: $white;
      font-weight: 600;

      &:hover {
        background-color: $primary-color;
        color: $white;
      }
    }
  }
}
